import React from "react";

const packageJson = require("../../package.json");

const Footer = () => {
  const version = packageJson.version;
  return (
    <>
      <footer className="w-full bg-pozadidark bottom-0">
        <div className="flex justify-center py-8">
          <div className="">
            <p>All rights reserved. www.janpluhar.cz</p>
            <p className="text-sm text-slate-400 text-center">
              App Version: {version}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
