import React from "react";
import { motion } from "framer-motion";
import ContactForm from "../components/ContactForm";
import ChangelogController from "../components/ChangelogController";

const packageJson = require("../../package.json");

const Contact = () => {
  const version = packageJson.version;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }} // Mírně zmenšené při načtení
      animate={{ opacity: 1, scale: 1 }} // Normální velikost a plná opacita
      exit={{ opacity: 0, scale: 0.95 }} // Mírně zmenšené při opuštění
      transition={{ duration: 0.15, ease: "easeInOut" }} // Plynulý přechod
    >
      <div>
        <div className="flex justify-center md:pr-52 font-semibold text-4xl px-8 py-8">
          Contact me
        </div>

        <div>
          <ContactForm />
        </div>

        <div className="mb-8">
          <div className="mt-16 max-w-md m-auto grid grid-cols-1 gap-4 shadow-lg text-center text-gray-400 text-sm md:grid-cols-2">
            <div className="px-8 py-8 bg-subpozadi rounded-lg">
              <p>+420 775 130 446</p>
            </div>
            <div className="px-8 py-8 bg-subpozadi rounded-lg">
              <p>hello@janpluhar.cz</p>
            </div>
          </div>
        </div>

        <ChangelogController />
      </div>
    </motion.div>
  );
};

export default Contact;
