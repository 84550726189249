import React, { useState } from "react";
import Swal from "sweetalert2";
import { PiChatDots } from "react-icons/pi";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [response, setResponse] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Show loader
      Swal.fire({
        title: "Sending message",
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const res = await fetch(
        "https://reactapp-janpluharweb-deploytest-backend.onrender.com/submitform/",
        // "https://reactapp-janpluharweb-deploytest-backend.onrender.com/submitform/", || "http://localhost:4000", || "https://janpluharcz-backend-dev.onrender.com/submitform/", (for development branch),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await res.json();

      if (res.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message,
        });
        // Reset form data
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message,
        });
      }

      setResponse(data.message);
    } catch (error) {
      console.error("Something went wrong ", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      setResponse("Something went wrong!");
    }
  };

  return (
    <div className="max-w-md m-auto">
      <div className="bg-pozadidark px-2 py-8 rounded-2xl shadow-lg md:border md:border-maincolor md:px-8">
        <PiChatDots size={48} className="mb-8 flex flex-col text-maincolor" />

        <form onSubmit={handleSubmit} className="flex flex-col gap-8">
          <div className="px-2 md:flex align-middle gap-4">
            <label className="my-auto w-28">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full h-10 px-4 py-4 border border-gray-300 rounded-xl bg-pozadidark focus:outline-none focus:border-maincolor"
            />
          </div>
          <div className="px-2 md:flex align-middle gap-4">
            <label className="my-auto w-28">Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full h-10 px-4 py-4 border border-gray-300 rounded-xl bg-pozadidark focus:outline-none focus:border-maincolor"
            />
          </div>
          <div className="px-2 md:flex align-middle gap-4">
            <label className="my-auto w-28">Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full h-44 px-4 py-4 border border-gray-300 rounded-xl bg-pozadidark focus:outline-none focus:border-maincolor"
            ></textarea>
          </div>
          <button className="buttonMain" type="submit">
            Send
          </button>
        </form>
        {response && <p>{response}</p>}
      </div>
    </div>
  );
}

export default ContactForm;
