import React from "react";
import { motion } from "framer-motion";
import TechIcons from "../components/others/TechIcons";

const Technologies = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }} // Mírně zmenšené při načtení
      animate={{ opacity: 1, scale: 1 }} // Normální velikost a plná opacita
      exit={{ opacity: 0, scale: 0.95 }} // Mírně zmenšené při opuštění
      transition={{ duration: 0.15, ease: "easeInOut" }} // Plynulý přechod
    >
      <div>
        <div className="pl-6 flex flex-col gap-2">
          <h1 className="font-bold text-2xl">Work in progress...</h1>
          <p>
            This page is currently under construction. Please check back later.
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default Technologies;
