import React from "react";
import { motion } from "framer-motion";

const Changelog = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }} // Mírně zmenšené při načtení
      animate={{ opacity: 1, scale: 1 }} // Normální velikost a plná opacita
      exit={{ opacity: 0, scale: 0.95 }} // Mírně zmenšené při opuštění
      transition={{ duration: 0.15, ease: "easeInOut" }} // Plynulý přechod
    >
      <div className="px-4 mt-0 ">
        <div className="flex w-full flex-col border-opacity-50"></div>
        <h3 className="mt-12">15.10.2024</h3>
        <div className="mb-2">
          <p className="text-sm text-slate-400">App Version: 0.1.6</p>
        </div>

        <ul>
          <li>- Contact form await fix</li>
          <li>- Added Contact popup (succes, error, wait)</li>
          <li>- Redesigned Changelog (open and close)</li>
          <li>- Contact responsivity fix</li>
        </ul>

        <h3 className="mt-12">14.10.2024</h3>
        <div className="mb-2">
          <p className="text-sm text-slate-400">App Version: 0.1.5</p>
        </div>

        <ul>
          <li>- Added Contact form</li>
          <li>- Quality of life</li>
          <li>- Changelog redesined</li>
          <li>- Technologies under construction</li>
        </ul>
        <h3 className="mt-12">28.09.2024</h3>
        <div className="mb-2">
          <p className="text-sm text-slate-400">App Version: 0.1.4</p>
        </div>

        <ul>
          <li>- Bug fixes</li>
        </ul>
        <h3 className="mt-12">27.09.2024</h3>
        <div className="mb-2">
          <p className="text-sm text-slate-400">App Version: 0.1.3</p>
        </div>

        <ul>
          <li>- Colors reworked</li>
          <li>- Apps portfolio reworked</li>
          <li>- Added new App "Cinaty"</li>
        </ul>
        <h3 className="mt-12">04.08.2024</h3>
        <div className="mb-2">
          <p className="text-sm text-slate-400">App Version: 0.1.2</p>
        </div>

        <ul>
          <li>- .gitignore and dependencies reworked</li>
          <li>- .env.dist added</li>
          <li>- .DS_store removed</li>
          <li>- Reloading pages with 404 reworked</li>
          <li>- Webhook deleted</li>
          <li>- Reloading after fetch deleted</li>
        </ul>
      </div>
    </motion.div>
  );
};

export default Changelog;
