import {
  AiOutlineInstagram,
  AiOutlineGithub,
  AiOutlineFacebook,
  AiOutlineLinkedin,
} from "react-icons/ai";

export default function Socials() {
  return (
    <>
      <div className="flex flex-row justify-around pt-10 items-center">
        <a href="https://www.linkedin.com/in/honzapluhar/" target="_blank">
          <AiOutlineLinkedin
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
        <a
          href="https://github.com/HonzaPluhar?tab=repositories"
          target="_blank"
        >
          <AiOutlineGithub
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
        <a href="https://www.instagram.com/honza_pluhar/" target="_blank">
          <AiOutlineInstagram
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
        <a href="https://www.facebook.com/pluharhonza" target="_blank">
          <AiOutlineFacebook
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
      </div>
    </>
  );
}
