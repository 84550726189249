import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Nav from "./components/Nav";
import About from "./pages/About";
import Technologies from "./pages/Technologies";
import Appsportfolio from "./pages/Appsportfolio";
import Contact from "./pages/Contact";
import SignUpApp from "./pages/sub/SignUpApp";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <div className="">
        <Nav />
        <div className="container px-4 pt-48 pb-12 min-h-screen max-w-xl mx-auto md:max-w-4xl">
          <AnimatePresence>
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="/technologies" element={<Technologies />} />
              <Route path="/appsportfolio" element={<Appsportfolio />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/signupapp" element={<SignUpApp />} />
            </Routes>
          </AnimatePresence>
        </div>
        <div className="bottom-0">
          <Footer />
        </div>
      </div>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
