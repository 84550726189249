import { React, useState } from "react";
import { SlArrowDown } from "react-icons/sl";
import Changelog from "./Changelog";

const ChangelogController = () => {
  // hide and show Changelog
  const [showChangelog, setShowChangelog] = useState(false);

  const handleClick = () => {
    setShowChangelog(!showChangelog);

    // Rotate arrow
    const arrowDown = document.getElementById("arrowDown");
    arrowDown.classList.toggle("rotated");
  };

  return (
    <div className="px-4 py-4">
      <div className="flex w-full flex-col border-opacity-50">
        <div className="divider">
          CHANGELOG
          <SlArrowDown
            size={50}
            className="fill-white ease-out duration-300 cursor-pointer hover:fill-green-400 "
            onClick={handleClick}
            id="arrowDown"
          />
        </div>
        {showChangelog ? <Changelog /> : null}
      </div>
    </div>
  );
};

export default ChangelogController;
