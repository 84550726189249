import React from "react";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <>
      <Link to="/">
        <img
          src="images/logowebwhite.webp"
          alt="Logo"
          width="120"
          height="50"
          className="cursor-pointer mx-auto hover:scale-110 transition duration-300 ease-in-out transform"
        />
      </Link>
    </>
  );
}
